// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
import bubbleio from "./assets/techstack/bubbleio1.png";
import bubbleio1 from "./assets/techstack/bubbleio.png";
import bubbleio2 from "./assets/techstack/bubbleio_icon.png";
import digitalocean from "./assets/techstack/digitalocean.png";
import laravel from "./assets/techstack/laravel-logo.png";
import php from "./assets/techstack/php2.png";
import wordpress from "./assets/techstack/wp-logo.png";

// Porject Images
import projectImage1 from "./assets/projects/lightdashboard.jpg";
import projectImage2 from "./assets/projects/nftmarketplace.jpg";
import projectImage3 from "./assets/projects/simple_user_management.jpg";
import projectImage4 from "./assets/projects/mairmair.PNG";
import projectImage5 from "./assets/projects/bestwatch.PNG";
import projectImage6 from "./assets/projects/finapac.PNG";
import projectImage7 from "./assets/projects/snapinnovation.PNG";
import projectImage8 from "./assets/projects/gamesplatform.PNG";
import projectImage9 from "./assets/projects/thbss.PNG";
import projectImage10 from "./assets/projects/scholarshipguidesg.PNG";
import projectImage11 from "./assets/projects/hardcopypreviewimg.PNG";
import projectImage14 from "./assets/projects/swotai.png";
import projectImage15 from "./assets/projects/smartsetc.png";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Billy Christi",
  tagline: "I build things for web",
  img: profile,
  about: `I'm a Software Engineer, specializing in Bubble.io. I developed websites using Bubble.io, PHP, Javascript, React, Laravel and many more. I have more than 5 years of experience as a professional software engineer.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/billychristihartanto/",
  github: "https://github.com/billycho",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Software Engineer",
    Company: `CSG International`,
    Location: "United States",
    Type: "Full Time",
    Duration: "March 2022 - June 2023",
  },
  {
    Position: "Software Engineer",
    Company: `Snap Innovations Pte Ltd`,
    Location: "Indonesia",
    Type: "Full Time",
    Duration: "May 2020 - Feb 2022",
  },
  {
    Position: "Mobile Developer",
    Company: `Astra Graphia Information Technology`,
    Location: "Jakarta, Indonesia",
    Type: "Full Time",
    Duration: "Oct 2018 - Sep 2019",
  },
  {
    Position: "Software Engineer",
    Company: `Mitrais`,
    Location: "Jakarta, Indonesia",
    Type: "Full Time",
    Duration: "Aug 2017 - Sep 2018",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Bachelor's degree in Business Information Systems",
    Company: "Petra Christian University",
    Location: "Indonesia",
    Type: "Full Time",
    Duration: "2013 - 2017",
  }
];

// Tech Stack and Tools
export const techStackDetails = {
  bubbleio: bubbleio1,
  wordpress: wordpress,
  digitalocean: digitalocean,
  laravel: laravel,
  php: php,
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "whatAIdea",
    image: projectImage14,
    description: `Developed an innovative platform offering SWOTAI for quick SWOT analysis and CYBERAI for instant cyber strategy assessments. Users can securely upload documents and receive comprehensive insights within minutes. This project enhances decision-making efficiency with fast, reliable data analysis.`,
    techstack: "Bubble.io, Make.com, PHP, OpenAI API, Stripe, API Connector",
    previewLink: "https://portal.whataidea.com/",
    githubLink: "https://github.com",
  },
  {
    title: "Smart SETC",
    image: projectImage15,
    description: `Developed a user-friendly website for determining eligibility for up to $32,220 in SETC relief funds. The site features a straightforward, question-based interface to assess qualifications, enhancing accessibility for self-employed individuals. This project showcases my ability to create efficient, user-centric solutions.`,
    techstack: "Bubble.io, Docusign, Javascript, TaxStatus, Stripe, API Connector",
    previewLink: "https://portal.smartsetc.com/",
    githubLink: "https://github.com",
  },
  {
    title: "The Happy Beavers Client Dashboard",
    image: projectImage9,
    description: `TBH Client dashboard is used by client to order translation, copywriting, editing and proofreading`,
    techstack: "Bubble.io, Airtable, Stripe, API Connector",
    previewLink: "https://thehappybeavers.com/",
    githubLink: "https://github.com",
  },
  {
    title: "Hardcopy.io",
    image: projectImage11,
    description: `Centrally store your important documents in the Hardcopy Cloud. No more using ad hoc systems, or even paper-based methods! With the Hardcopy platform you and your team can securely file your docs and search with ease.`,
    techstack: "Bubble.io, PHP, JS, MySQL, HTML/CSS",
    previewLink: "https://hardcopy.io/",
    githubLink: "https://github.com",
  },
 
  {
    title: "Scholarship Guide Singapore",
    image: projectImage10,
    description: `Scholarship Guide Singapore is a comprehensive platform offering detailed information on scholarships available in Singapore. Students can easily apply for various scholarships directly through the website, while advertisers can post their scholarship opportunities to reach a wider audience.`,
    techstack: "PHP, JS, Yii, MySQL, HTML/CSS",
    previewLink: "http://scholarshipguide.com.sg/",
    githubLink: "https://github.com",
  },
  {
    title: "mair-mair.com",
    image: projectImage4,
    description: `Developed an Italian Wine E-Commerce platform that seamlessly connects wine enthusiasts with a curated selection of authentic Italian wines. This project highlights my expertise in e-commerce solutions, user experience design, and the integration of secure payment systems.`,
    techstack: "PHP, MySQL, HTML, CSS, Bootstrap",
    previewLink: "https://mair-mair.com",
    githubLink: "https://github.com",
  },
  {
    title: "Light Dashboard",
    image: projectImage1,
    description: `Light Dashboard is a clean & responsive dashboard using white color palette as the base color, main features include table templates, form templates, graphs, user accounts, profiles, responsive sidebar`,
    techstack: "Bubble.io",
    previewLink: "https://light-dashboard.bubbleapps.io",
    githubLink: "https://github.com",
  },
  {
    title: "Simple User Management",
    image: projectImage3,
    description: `Simple User Management is the most simple user management dashboard for Bubble`,
    techstack: "Bubble.io",
    previewLink: "https://simple-user-management.bubbleapps.io/",
    githubLink: "https://github.com",
  },
  
  {
    title: "NFT Marketplace",
    image: projectImage2,
    description: `NFT Marketplace is a marketplace where you can sell your own NFT and buy NFT
    `,
    techstack: "Bubble.io",
    previewLink: "https://nftpagetemplate.bubbleapps.io/",
    githubLink: "https://github.com",
  },
  {
    title: "React Movie App",
    image: projectImage5,
    description: `Movie App developed using React.`,
    techstack: "React, Redux, Javascript, HTML, CSS, Bootstrap",
    previewLink: "http://bestwatch.billychristi.com/",
    githubLink: "https://github.com",
  },
  {
    title: "Finapac.sg",
    image: projectImage6,
    description: `Finapac is fund management company from Singapore`,
    techstack: "WordPress, HTML/CSS",
    previewLink: "https://finapac.sg/",
    githubLink: "https://github.com",
  },
  {
    title: "Snapinnovations.com",
    image: projectImage7,
    description: `Snap Innovations is an AI and Blockchain company`,
    techstack: "WordPress, HTML/CSS",
    previewLink: "https://snapinnovations.com/",
    githubLink: "https://github.com",
  }
];

// Enter your Contact Details here
export const contactDetails = {
  email: "billychartanto@gmail.com",
  phone: "",
};
